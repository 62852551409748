.container .form-box {
  max-width: unset;
  margin: auto;
}
.is-invalid {
  border: 1px solid var(--bs-form-invalid-border-color) !important;
}
@media screen and (max-width: 767px){
  .contact-form-box{
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
    margin-top: 20px !important;
  }
}