@import '../../vehicleDetails.scss';

.skip-button {
    color: #222;
    font-weight: 700;
    background-color: var(--light_grey);
    border: none;
}

.skip-button:hover {
    background-color: var(--blue);
    color: white;
}

.add-lien-holder-link  {
    color: var(--blue);
    cursor: pointer;
}

.add-lien-holder-link:hover  {
    cursor: pointer;
    text-decoration: underline;
}
