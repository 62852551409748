.edit-btn {
    border-width: var(--bs-border-width) 0;
    border-color: var(--bs-border-color);
    border-style: solid;
    padding: 13px 10px 12px 10px !important;
    border-radius: unset;

    svg:hover {
        background-color: white !important;
    }
}

.edit-btn:hover {
    border-width: var(--bs-border-width) 0;
    border-color: var(--bs-border-color);
    border-style: solid;
    cursor: pointer !important;
}

.edit-btn:active {
    border-width: var(--bs-border-width) 0;
    border-color: var(--bs-border-color) !important;
}

.delete-btn {
    border: var(--bs-border-width) solid var(--bs-border-color);
    padding: 13px 10px;
    border-radius: unset;
}

.delete-btn:hover {
    border: var(--bs-border-width) solid var(--bs-border-color);
    cursor: pointer !important;
}

.delete-btn:active {
    border: var(--bs-border-width) solid var(--bs-border-color) !important;
}