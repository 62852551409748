@import '../../../../styles/formStyles.scss';

.info-form-box {
  background-color: var(--very-light_blue);
  max-width: 100%;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 3px 4px 0 #ccc;
}
.dropdown-menu.show{
  right: 0;
}
.ml-50{
  margin-left: 50px;
}
.ml-70{
  margin-left: 70px;
}
.height-75{
  height: 75px;
}

.menu {
  position: fixed;
  top: 24px;
  z-index: 9999;
  left: 15px;
  svg{
    width: 20px;
  }
}
.accountNav{
  position: fixed;
  left: 0;
  right: 0;
  height: 75px;
  z-index: 9999;
  box-shadow: unset;
  border-bottom: 1px solid #d5d5d5;

}
@media (max-width: 750px) {
  .sidebar-col2 {
    display: none;
  }
}

@media screen and (max-width: 1029px) {
  .sidebar-col1 {
    width: 320px;
    z-index: 2000;
  }
  .display-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    z-index: 1;
  }
}
@media screen and (max-width: 575px) {
  .sidebar-col1 {
    width: 100%;
  }
}
@media screen and (max-width: 1429px) {
  .notification-content {
    right: -154%;
  }
}

.notification {
  position: fixed;
  top: 2%;
  right: 20%;
  z-index: 1963;
}

.notification-content {
  width: 350px;
  height: 350px;
  position: absolute !important;
  right: 20;
  top:130%
}

.notification-title:hover {
  background-color: var(--blue);
}

.notification-icon {
  color: white;
  border-radius: 50%;
  padding: 5px;
}

.activity {
  position: absolute;
  bottom: 5%;
  right: 64px;
}

// Mobile responsive
@media screen and (max-width: 767px){
  .container_override-sm{
    padding: 0;
    box-shadow: unset !important;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    margin-bottom: 40px !important;
    background-color: white;

  }
  .tabs-parent{ 
    .tab-content{
      padding: 20px !important;
    }
  }
}
@media screen and (max-width: 575px){
  .jCenter-sm{
    justify-content: center !important;
  }
 
}