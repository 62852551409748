.nav-link.font-size {
  font-size: 10px;
}

.navbar-nav {
  .nav-link.active.border-line {
    @media screen and (min-width: 992px) {
      border-bottom: 3px solid var(--blue-color);
      transition: 0.3s;
    }
  }
}

.navbar-nav {
  @media screen and (min-width: 992px) {
    gap: 20px;
  }
}

@media screen and (max-width: 1029px) {
  .navbar-brand{
    margin-right: 0 !important;
    // margin-left: 40px;
  }
}


@media screen and (max-width: 500px){
  .flex-wrap-unset{
    flex-wrap: unset !important;
  }
}

@media screen and (max-width: 991px){
  .navbar-collapse{
    position: absolute;
    background: white;
    top: 67px;
    left: 0px;
    right: 0px;
    // padding: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .navbar-toggler{
    border: unset !important;
    padding: 0 !important;
    &:focus{
      outline: unset !important;
      box-shadow: unset !important;
    }

  }
}