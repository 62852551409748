table {
  border-collapse: collapse;
  table-layout: fixed;
  width: -webkit-fill-available;
}
th {
  background: #f5f5f5;
  display: table-cell !important;
}

td,
th {
  height: 53px;
  border: 1px solid #ddd;
  padding: 10px;
  empty-cells: show;
}

.bg-purple {
  border-top: 3px solid #a32362;
}
.bg-red {
  border-top: 3px solid #fc4903;
}
.bg-blue {
  border-top: 3px solid #0097cf;
}
.bg-green {
  border-top: 3px solid green;
}
.sep {
  background: #f5f5f5;
  font-weight: bold;
}
.tick {
  font-size: 18px;
  color: #2ca01c;
}
.hide {
  border: 0;
  background: none;
}

@media (min-width: 640px) {
  ul {
    // display: none;
  }
  td,
  td,
  th {
    width: 330px;
  }
  td + td,
  th + th {
    width: auto;
  }
}

.NT-link-text {
  text-decoration: none;
  font-size: 14px;
  text-align: end;
}
