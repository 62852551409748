.circle-inner {
  color: var(--blue);
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  height: 50px;
  width: 50px;
  font-size: 15px;
}

td, td, th {
  width: unset;
}


.driver_details_card{
  .driver_details_inner{
    padding: 15px;
    box-shadow: 0 2px 4px 0 #ccc;
    border-radius: 10px;
    margin-bottom: 15px;
    transition: .3s;
    .driver_details_profile{
      display: flex;
      .driver_details_profile_icon{
        width: 24px;
        height: 24px;
        min-width: 24px;
        background-color: gray;
        color: white;
        text-transform: uppercase;
        font-size: 12px;
        border-radius: 25px;
        margin-right: 5px;
        display: inline-block;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;

      }
    }
    label{
      font-size: 12px;
      color: #333;
      text-align: left;
      font-weight: 600;
      margin-bottom: 4px;
    }
    div{
      font-size: 14px;
      text-align: left;
    }
    &:hover{
      box-shadow: 0 0 4px 0 #ccc;
      transition: .3s;
    }
    @media screen and (max-width: 575px){
      // box-shadow: unset;
      // padding: 0;
      border-bottom: 1px solid #ccc;
      // border-radius: 0;
      padding-bottom: 10px;
      &:hover{
        // box-shadow: unset;
      }
    }
    .bb-1{
      border-bottom: 1px solid #ccc;
      padding-bottom: 10px;
    }
  }
}

.info-icon{
  padding-left: 10px;
}
