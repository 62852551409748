.contact_inner{
  // box-shadow: 0 0 4px 0 #ccc;
  text-align: center;
  // border-radius: 20px;
  // background-color: #f1f1f1;
  // padding: 30px;
}
.categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .category {
    display: flex;
    color: var(--blue);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 240px;
    min-width: 240px;
    width: 240px;
    min-height: 240px;
    padding: 32px;
    margin: 16px;
    border-radius: 8px;
    transition: box-shadow 225ms cubic-bezier(0.4, 0, 0.2, 1);
    background: #fff;
    .title {
      margin-top: 24px;
      font-size: 16px;
      font-weight: 600;
    }

    .subtitle {
      margin-top: 2px;
      text-align: center;
      line-height: 1.3;
    }
  }
}
