.reminderForm input[type='text'],
[type='date'],
select,
textarea {
  background: none;
  border: none;
  border-bottom: solid 1px #ccc;
  color: #474544;
  font-size: 1em;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0em 0 1.875em 0;
  padding: 0 0 0.875em 0;
  text-transform: uppercase;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  &:focus{
    outline: unset;
    box-shadow: unset;
  }
}

.reminderForm input[type='text']:focus,
[type='email']:focus,
textarea:focus {
  outline: none;
  padding: 0 0 0.875em 0;
}

.backgroundReminder {
  background-color: rgba(18, 99, 169, 0.04);
}
