@import '../../home.scss';

.featured .steps-box {
  background: var(--white);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 25px 35px;
  align-self: flex-start;
  position: relative;
  flex: 1 1;
  min-height: 420px;
}
@media screen and (max-width: 991px){
  .featured .steps-box{
    min-height: unset;
    margin: 0 !important;
  }
}
.featured .steps-box:hover{
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.featured .steps-box p {
  color: var(--grey);
}
.steps-box .icon > img {
  height: 70px;
  width: 70px;
}

.featured .steps-box .icon:before {
  background: var(--blue);
  opacity: 0.15;
  content: '';
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  z-index: 1;
  left: 4%;
  top: 3%;
}

.row1 .design_element2 img {
  position: absolute;
  right: 73%;
  top: 180%;
  z-index: -4;
}

.row2 .design_element2 img {
  position: absolute;
  right: 20%;
  top: 260%;
  z-index: -4;
}
