.social-icons .icon {
  background: var(--white);
  width: 40px;
  height: 40px;
  padding: 12px;
  border-radius: 50%;
}

.social-icons {
  gap: 15px;
}

.borderBlue {
  border-top: 2px solid var(--blue);
}
