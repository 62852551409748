@media screen and (max-width: 767px){
	.font-20-sm{
		font-size: 20px !important;
	}
	.font-24-sm{
		font-size: 24px !important;
	}
	.partials_payments{
		padding: 0 !important;
		background: unset !important;
		box-shadow: unset !important;
		.border{
			background-color: white;
			padding-top: 50px !important;
			.paymentActionBtn{
				position: absolute;
				top: 15px;
				right: 15px;
			}
		}
	}
}

.docusignHeight {
	height: 700px;
}

