.icons-custom-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: white;
  color: black;
}

.icons-custom-options.is-focused {
  background-color: var(--blue);
  color: white;
}
