.details-body {
  background: linear-gradient(310deg, rgb(33 161 255), rgb(2 71 202));
  padding: 2.5rem;
  color: white;
  position: relative;
}

.card-img-absolute {
  position: absolute;
  top: 0;
  right: 0%;
  height: 100%;
  pointer-events: none;
}

.details-container .card-btn {
  float: right;
}
