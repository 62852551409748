@import '../getQuote.scss';

.main-container {
  display: flex;
  align-items: flex-start;
  background-color: var(--light_blue--alt);
  box-shadow: var(--heavy-box-shadow);
  padding: 30px;
  border-radius: var(--bs-border-radius-xxl);
}

.steps-container {
  flex: 1;
}

.content-container {
  background: white;
  flex: 2;
  padding: 20px;
  height: 500px;
}

.content-container:hover {
  border: 1px solid lightblue;
  box-shadow: var(--box-shadow);
}

.details-container {
  padding: 5% 5%;
  background-color: white;
  box-shadow: var(--box-shadow);
  border-radius: var(--bs-border-radius-xl);
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: center;
}

.current-container .nav-pills {
  // background-color: var(--very-light_blue);
}

.tab-button .nav-link.active {
  background-color: var(--blue);
}

.vehicle-edit-icon {
  color: var(--blue);
}

.vehicle-edit-icon:hover {
  background-color: lightgrey;
  border-radius: 10px;
  cursor: pointer;
}


.nav-pills{
  margin: 30px 0;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  padding: 0;
  .nav-item{
    width: 50%;
    .nav-link{
      padding: 12px 0;
      border-radius: 15px 15px 0 0;
    }
  }
}
.nav-three{
  .nav-item{
    width: 33.3%;
  }
}

