@import '../../../../../../styles/formStyles.scss';

.logout{
    min-height: calc(100vh - 180px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    &_logout_child{

    }
}