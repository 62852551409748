.card {
  background-color: var(--light_blue);
  border-radius: var(--border-radius);
  box-shadow: 0px 0px 4px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  padding: 30px;
  border: 2px solid #ccc;
  &:hover {
    box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.15);
    // transform: scale(1.05);
    // transition: 0.5s;
    // border: 2px solid #7cc2ff;
  }
}

.radio {
  font-size: inherit;
  margin: 0;
  position: relative;
  right: calc(var(--card-padding) + var(--radio-border-width));
  top: calc(var(--card-padding) + var(--radio-border-width));
  .form-check-input {
    position: absolute;
    right: 0;
  }
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #fff;
    border: var(--radio-border-width) solid var(--color-gray);
    border-radius: 50%;
    cursor: pointer;
    height: var(--radio-size);
    outline: none;
    transition:
      background 0.2s ease-out,
      border-color 0.2s ease-out;
    width: var(--radio-size);

    &::after {
      border: var(--radio-border-width) solid #fff;
      border-top: 0;
      border-left: 0;
      content: '';
      display: block;
      height: 0.75rem;
      left: 25%;
      position: absolute;
      top: 50%;
      transform: rotate(45deg) translate(-50%, -50%);
      width: 0.375rem;
    }

    &:checked {
      background: var(--color-green);
      border-color: var(--color-green);
    }
  }

  .card:hover .radio {
    border-color: var(--color-dark-gray);

    &:checked {
      border-color: var(--color-green);
    }
  }
}

.plan-details {
  border: var(--radio-border-width) solid var(--color-gray);
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  transition: border-color 0.2s ease-out;
}

.card:hover .plan-details {
  border-color: var(--color-dark-gray);
}

.radio:checked ~ .plan-details {
  border-color: var(--blue);
}

.radio:focus ~ .plan-details {
  box-shadow: 0 0 0 2px var(--color-dark-gray);
}

.radio:disabled ~ .plan-details {
  color: var(--color-dark-gray);
  cursor: default;
}

.radio:disabled ~ .plan-details .plan-type {
  color: var(--color-dark-gray);
}

.card:hover .radio:disabled ~ .plan-details {
  border-color: var(--color-gray);
  box-shadow: none;
}

.card:hover .radio:disabled {
  border-color: var(--color-gray);
}

.plan-type {
  color: var(--blue);
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1em;
}

.plan-cost {
  font-size: 2.5rem;
  font-weight: bold;
  padding: 0.5rem 0;
}

.slash {
  font-weight: normal;
}

.plan-cycle {
  font-size: 2rem;
  font-variant: none;
  border-bottom: none;
  cursor: inherit;
  text-decoration: none;
}

.selected {
  border: 2px solid var(--blue) !important;
  &:hover{
    border-color: var(--blue);
  }
}

// ribbon CSS

.box {
  position: relative;
}

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  top: -10px;
  left: 3px;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #db3c34;
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 230px;
  padding: 7px 0;
  background-color: #db3c34;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font:
    700 18px/1 'Lato',
    sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

.ribbon::before {
  top: 0;
  right: 0;
}
.ribbon::after {
  bottom: 0;
  left: 0;
}
.ribbon span {
  right: -25px;
  top: 55px;
  transform: rotate(-45deg);
  @media screen and (max-width: 767px){
    font-size: 8px;
  }
}

@media (min-width: 686px) and (max-width: 1381px) {
  .box {
    // width: 50%; /* 3 columns in a row at 1381px */
  }
}





@media screen and (max-width: 767px){
  .ribbon{
    width: 70px;
    height: 70px;
    span{
      width: 110px;
      right: -14px;
      top: 20px;
    }
  }
}

// Mobile Responsive Class
.plan_mobile_res{
  .box{
    // width: 50%;
    .card{
      padding: 10px !important;
    }
  }
}