.quote-container .ant-steps-horizontal.custom-steps .ant-steps-item-content {
  width: 100%;
  text-align: left;
  background: aliceblue;
  padding: 20px 20px;
  border: 2px solid var(--form-blue);
  position: relative;
  display: flex;
  border-radius: 50px;
  
}

.quote-container .ant-steps-horizontal.custom-steps .ant-steps-item-content {
  width: 100%;
}



