.account-wrapper{
  margin: 20px;
  @media screen and (max-width: 575px){
    margin-left: 0;
    margin-right: 0;
  }
}

.current-container-parent .design_element2 {
  position: absolute;
  right: 0px;
  bottom: 2rem;
  z-index: -1;
}

.current-container-parent .design_element1 {
  position: absolute;
  left: 18rem;
  top: 3rem;
  z-index: -1;
}

@media (max-width: 800px) {
  .current-container-parent .design_element1,
  .current-container-parent .design_element2 {
    display: none;
  }
}




// Basic panel for theme
.panel_theme{
  border-radius: 20px 20px 0 0;
  .panel_theme_head{
    border-radius: 18px 18px 0 0;
    background-color: var(--blue);
    color: white;
    padding: 10px 20px;
    font-size: 20px;
  }
  .panel_theme_body{
    background-color: white;
    padding: 30px;
    box-shadow: 0 0 4px 0 #ccc;
    .panel_mini_heading{
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      border-bottom: 1px solid #ccc;
      padding-bottom: 8px;
    }
    @media screen and (max-width: 575px){
      padding: 20px;
    }
  }
}
.tabs-parent{
  border-radius: 20px 20px 0 0; 
  .nav-pills{
    margin-bottom: 0;
    margin-top: 0;
    .tab-button{
      background-color: white;
      border-radius: 20px 20px 0 0;
      box-shadow: 0 0 4px 0 #ccc;
    }
  }
  .tab-content{
    background-color: white;
    padding: 30px;
    box-shadow: 0 0 4px 0 #ccc;
    @media screen and (max-width: 575px){
      padding: 20px;
    } 
  }
}