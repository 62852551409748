.drag-drop-section {
    span {
        justify-content: center;
        display: flex;
    }
}

.upload-document-button {
    display: flex;
}

.document-title-section {
    display: flex;
    justify-content: space-between;
}

.popup-overlay {
    background-color: rgba(0, 0, 0, 0.7);
}

.react-confirm-alert-overlay {
    background: rgba(0, 0, 0, 0.7) !important;
}

.file-types {
    display: none !important;
}

.delete-document-modal {
    margin-top: 5%;
}

.delete-document-loader {
    display: flex;
    justify-content: center;
}
