.silo_page-container {
  min-height: calc(100vh - 147.3px);
  background-size: cover;
  background-position: center;

  &.loader-layout {
    position: fixed;
    z-index: 999999999;
    min-height: 100vh;
    background: #eff8ff;
    width: 100%;
    overflow: hidden;
    top: 0;
  }
}

.login-form-img {
  width: 420px;
  margin: 15px 0 0 25px;
}

.contact-form-box {
  max-width: 600px;
  margin-left: auto;
  padding: 40px;
  background: var(--white);
  border-radius: var(--border-radius);
  box-shadow: var(--heavy-box-shadow);
}

.contact-form-box form .form-group>input,
.contact-form-box form .form-group>select {
  border-radius: var(--border-radius);
  padding: 16px 25px;
  height: auto;
  margin-bottom: 20px;
  border: 0;
  box-shadow: var(--box-shadow);
}

.contact-form-box form .form-group>input[type='email'],
.contact-form-box form .form-group>input[type='password'],
.contact-form-box form .form-group>input[type='text'],
.contact-form-box form .form-group>input[type='number'],
.contact-form-box form .form-group>select {
  padding-left: 55px;
}

.contact-form-box form .form-group {
  position: relative;
}

.contact-form-box form .form-group>.icon {
  width: 20px;
  left: 20px;
  position: absolute;
  right: 0;
  top: 15px;
  margin: 0;
  padding: 0;
}

.contact-form-box form .form-group>.icon:last-child {
  right: 20px;
  left: auto;
  cursor: pointer;
}

.dotted-lines {
  position: absolute;
  left: 46px;
  width: 55%;
  top: -18px;
}

@media (max-width: 1400px) {
  .login-form-img {
    width: 350px;
    margin: 25px 0 0 10px;
  }

  .dotted-lines {
    top: -10px;
  }
}

@media (max-width: 1200px) {
  .login-form-img {
    width: 325px;
  }

  .dotted-lines {
    top: 0;
  }
}

@media screen and (max-width: 575px) {
  .login-form-img {
    display: none;
  }
}

@media (max-width: 992px) {
  .dotted-lines {
    display: none;
  }
}

.user-detail-form-blue .form-control,
.form-select {
  padding: 10px;
  background-color: white;
  color: black;
}

.user-detail-form-blue .form-control:focus {
  border: 1px solid rgba(13, 110, 253, 0.25);
  box-shadow: unset;
}

.user-detail-form-blue .custom-select .css-1h06qz8-control,
.css-cmgjre-control {
  padding: 1%;
  background-color: white;
}

.user-detail-form-blue .custom-select .css-lutf1-control {
  padding: 1%;
}

.user-detail-form-blue .custom-select .css-t3ipsp-control:hover {
  border-color: aliceblue;
}

.custom-select .select-placeholder-text {
  color: slategray;
  font-size: 16px;
}

.custom-select.is-invalid {
  border: 1px solid var(--bs-form-invalid-border-color);
  border-radius: 5px;
}

.react-datepicker-wrapper .is-invalid {
  border: 1px solid var(--bs-form-invalid-border-color);
  border-radius: 5px;
}

.react-datepicker-wrapper {
  outline: none;
}

.user-detail-form-blue .input-group-text {
  background-color: white;
}

.user-detail-form-blue .custom-select .css-13cymwt-control,
.css-t3ipsp-control {
  background-color: white;
  padding: 1%;
}

.current-container {
  padding: 5% 5%;
  background-color: white;
  box-shadow: var(--heavy-box-shadow);
  border-radius: var(--bs-border-radius-xl);

  @media screen and (min-width: 1500px) {
    padding: 40px;
  }
}

.ellipsis {
  padding: 5px;
}

.ellipsis:hover {
  background-color: var(--light_grey);
  border-radius: 50%;
  padding: 5px;
}

.back-btn-style {
  padding: 8px;
}

.back-btn-style:hover {
  background-color: #e6f4ff;
  border-color: #e6f4ff;
  border-radius: 40px;
}


@media screen and (max-width: 767px) {
  .contact-form-box {
    padding: 20px;
  }
}

.vin-field-save-btn {
  background: #1263a9;
  color: #fff;
  border: 1px solid #1263a9;
}

.vin-field-save-btn:hover {
  background: #1263a9;
  color: #fff;
  border: 1px solid #1263a9;
}


.fail {
  color: #c95400;
  color: #e36002;
  background-color: #F5DBD9;
  border: 1px solid #EBAFA6;
  border-radius: var(--border-radius);
  padding: 1px 10px;
}
.expired {
  color: rgb(176, 3, 3);
  background-color: #F5DBD9;
  border: 1px solid #EBAFA6;
  border-radius: var(--border-radius);
  padding: 1px 10px;
}

.success {
  color: #266900;
  background-color: #DFF0D0;
  border: 1px solid #60b515;
  border-radius: var(--border-radius);
  padding: 1px 10px;
}
