/*--Banner Section--*/
.silo_page-banner {
  background-image: url(../../../assets/images/Home/banner-bg-graphics.avif);
  padding: 100px 0 80px;
  background-size: cover;
  background-position: bottom;
  min-height: 100vh;
  margin-top: calc(var(--navbar-height) * -1);
}

/*--Welcome SILO Members Section--*/
.silo_page-welcome-members {
  position: relative;
  overflow: hidden;
}
.silo_page-welcome-members .design_element2 {
  position: absolute;
  right: -150px;
  bottom: -100px;
}
.silo_page-welcome-members .image-holder {
  position: relative;
  padding: 25px 0 0;
}
.silo_page-welcome-members .image-holder .design_element1 {
  position: absolute;
  right: 45px;
  width: 20%;
  z-index: -1;
  top: 0;
}
ul.silo_ul-pointers {
  position: relative;
  list-style: none;
  margin-bottom: 2rem;
}
ul.silo_ul-pointers li {
  position: relative;
  margin-bottom: 0.5rem;
  font-weight: 600;
}
ul.silo_ul-pointers li:before {
  content: '';
  width: 14px;
  height: 14px;
  background: url(../../../assets/images/Home/list-style.png);
  top: 5px;
  display: block;
  background-size: cover;
  position: absolute;
  left: -24px;
}

/*--How it Works--*/
.silo_page-how-it-works {
  position: relative;
  background-image: url(../../../assets/images/Home/Features/features_section-bg.png);
}
.silo_page-how-it-works .design_element2 {
  position: absolute;
  text-indent: -150px;
  top: -20px;
}
.silo_page-steps-boxes {
  background-image: url(../../../assets/images/Home/steps-line.png);
  background-size: cover;
  background-position: -15px 0;
  background-repeat: no-repeat;
  max-width: 1600px;
  margin: auto;
}
.silo_page-steps-boxes .d-flex {
  gap: 60px;
}
.silo_page-steps-boxes .steps-box {
  background: var(--white);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 25px;
  align-self: flex-start;
  position: relative;
  flex: 1;
  min-height: 300px;
}
.silo_page-steps-boxes .steps-box:after {
  content: attr(step);
  position: absolute;
  right: 20px;
  bottom: -20px;
  color: #f6f8fa;
  font-size: 120px;
  line-height: 1;
  z-index: 0;
}
.silo_page-steps-boxes .steps-box h4 {
  font-weight: 600;
}
.silo_page-steps-boxes .steps-box p {
  z-index: 2;
  position: relative;
}
.silo_page-steps-boxes .steps-box .icon {
  margin-bottom: 20px;
  position: relative;
  padding: 15px 0 0 15px;
}
.silo_page-steps-boxes .steps-box .icon:before {
  background: var(--blue);
  opacity: 0.15;
  content: '';
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  z-index: 1;
  left: 0;
  top: 0;
}
.silo_page-steps-boxes .steps-box .icon > img {
  height: 70px;
  width: 70px;
}

@media (max-width: 600px) {
  .silo_page-banner {
    background-image: url(../../../assets/images/Home/banner-bg.avif);
    padding: 50px 0px;
    // min-height: 80vh;
  }

  .silo_page-steps-boxes .d-flex {
    flex-direction: column;
    gap: 20px;
  }
  .silo_page-steps-boxes .d-flex .steps-box {
    margin: 20px 0 0 !important;
    width: 100%;
  }
}


/*--Typography--*/
h1 {
  font-size: 3rem;
  margin: unset;
  padding: unset;
  font-weight: 700;
}
h2 {
  font-size: 2.4rem;
  font-weight: 700;
}
h4 {
  font-size: 1.5rem;
  font-weight: 700;
}



/*--Page Header & Heading--*/
.silo_page-header ol.breadcrumb {
  font-size: 1.2rem !important;
  text-transform: uppercase !important;
  background-color: transparent !important;
  font-weight: 400 !important;
  padding: 0px !important;
}
.silo_page-header ol.breadcrumb > li.active {
  color: var(--blue) !important;
  font-weight: 600 !important;
}

.silo_page_section-heading small {
  color: var(--blue) !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  letter-spacing: 4px !important;
}
.silo_page_section-heading h2 {
  font-size: 2.5rem !important;
  font-weight: 700 !important;
  margin: 0.5rem 0 !important;
  line-height: 1.3 !important;
}
@media screen and (max-width: 767px){
  .silo_page_section-heading h2{
    font-size: 28px !important;
  }
}
.silo_page_section-heading p {
  font-size: 1rem !important;
  color: var(--grey) !important;
  margin-bottom: 0px;
  font-size: 14px !important;
}

/*--Insurance Section--*/
.silo_page-insurance {
  position: relative;
  overflow-x: hidden;
}
.silo_page-insurance .design_element1 {
  position: absolute;
  left: -150px;
  top: -100px;
}
.silo_page-insurance .design_element2 {
  position: absolute;
  right: -150px;
  top: -100px;
}

/*--Footer--*/
footer.silo_footer {
  /* background-image: url('../assets/images/footer-bg.png'); */
  background-size: cover;
  color: var(--white);
}
footer.silo_footer .footer-top {
  border-bottom: 1px solid var(--white);
}

footer.silo_footer .footer-top ul {
  flex-wrap: wrap;
  gap: 25px;
}

footer.silo_footer .footer-bottom ul {
  list-style: none;
  gap: 7.5px;
  margin: 0;
}
footer.silo_footer .footer-bottom ul:not(.social-icons) li:not(:first-child) {
  padding-left: 7.5px;
  border-left: 1px solid;
}
ul.list-inline.social-icons li .icon {
  background: var(--white);
  width: 40px;
  height: 40px;
  display: flex;
  padding: 12px;
  border-radius: 50%;
}

footer.silo_footer ul > li a.nav-link {
  color: var(--white);
}

@media (max-width: 1200px) {
  /*--Typography--*/
  h1 {
    font-size: 2.8rem;
  }
  h2 {
    font-size: 2.2rem;
  }
  h4 {
    font-size: 1.4rem;
  }
}

@media (max-width: 900px) {
  /*--Typography--*/
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 2rem;
  }
  h4 {
    font-size: 1.1rem;
  }

  footer.silo_footer .footer-bottom ul.list-inline.d-flex.social-icons {
    margin: 20px 0;
    justify-content: center;
  }
}

@media (max-width: 600px) {
  /*--Typography--*/
  h1 {
    font-size: 2rem;
    font-weight: 700;
  }
  h2 {
    font-size: 1.8rem;
    font-weight: 700;
  }
  h4 {
    font-size: 0.8rem;
    font-weight: 700;
  }

  nav.silo_navbar.navbar {
    background: #fff;
  }

  .design_element1,
  .design_element2 {
    display: none;
  }

  footer.silo_footer .footer-bottom .d-flex {
    text-align: center;
    justify-content: center;
  }
}
