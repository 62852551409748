.grid-container {
    display: grid;
    grid-template-columns: 3fr 1fr; 
    grid-gap: 10px; 
    align-items: center;
  }
  
  .grid-item-left {
    grid-column: 1;
    text-align: start;
  }
  
  .grid-item-subscription {
    grid-column: 1;
  }
  
  .grid-item-right {
    grid-column: 2;
    text-align: end;
  }
  
  .grid-item-delete {
    grid-column: 1;
    text-align: right;
  }
  