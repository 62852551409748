.edit-btn {
  color: var(--blue);
  padding: 10px;

  :hover {
    background-color: var(--light_grey);
    border-radius: 10px;
  }
}

.min-height {
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-name-icon {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  font-size: 80px;
  font-family: roboto, arial;
  color: white;
  line-height: 1.9;
  text-align: center;
  background: #1362a9;
  display: inline-block;
  @media screen and (max-width: 575px){
    font-size: 50px;
    height: 90px;
    width: 90px;
    margin-bottom: 30px;
  }
}

.info-text {
  font-size: 18px;
}