.not-found-parent {
  height: 100vh;
  width: 100%;
  background: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  .img-parent {
    width: 100%;
    height: 550px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
