#DOBuser {
  margin-bottom: 0px;
}

.vin-label {
  display: flex;
}

.vin-note {
  text-align: start;
}

.countdown-text {
  font-size: 1.5rem;
}
