.silo_page-home {
  // background: linear-gradient(to left, #f8fcfc 50%, transparent 50%);
  // background-image: url('../../../static/media/features_section-bg.png');
  background-image: url(../../../../../assets/images/Home/Features/features_section-bg.png);
  // background-size: 100% 50%;
  // position: relative;
      padding: 100px 0;

}
@import '../../home.scss';

.silo_page_section-heading .design_element1 {
  position: absolute;
  right: -100px;
  top: -12px;
  z-index: -1;
}

.silo_page_section-heading {
  position: relative;
  overflow-x: hidden;
}

@media screen and (max-width: 767px){
  .silo_page-home{
    padding: 50px 0;
  }
}