.react-datepicker-wrapper {
    width: 100%;
  }
  
  .custom-year-datepicker {
    width: 100%;
    padding: 2.5%;
    background-color: white;
    border-color: hsl(0, 0%, 80%);
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    font-size: 16px;
  }
  
  .custom-year-datepicker:focus-visible {
    outline: none;
  }
  
  .react-datepicker-wrapper .custom-year-datepicker:focus {
      border: 2px solid var(--blue)
  }
  
  .react-datepicker .react-datepicker__year-wrapper{
      max-width: 375px;
      gap: 20px;
      font-size: 1rem;
  }
  
  .react-datepicker .react-datepicker__year-text--keyboard-selected{
      background-color: var(--blue);
      color: white;
  }
  
  .react-datepicker__input-container:focus-visible {
    outline: none;
  }
  
  .react-datepicker-wrapper .react-datepicker__close-icon {
    margin-right: 10px;
  }