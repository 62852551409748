.otp-input {
  width: 3rem !important;
  height: 2.5rem;
  margin: 0 0.25rem;
  font-size: 1.5rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  outline: none;
  border: 2px solid var(--blue);
  @media screen and (max-width: 576px){
    width: 100% !important;
    height: 50px;
  }
}
