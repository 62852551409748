.w_20px {
  width: 20px;
}

.driver-checkbox {
  label {
    cursor: pointer;
  }
}

.user-detail-form-blue input:disabled {
  background-color: #f1f1f1;
  cursor: not-allowed;
}

.margin-right {
  margin-right: 1rem;
}

.margin-left {
  margin-left: 1rem;
}

.upload-btn {
  color: var(--blue);
  &:hover {
    background-color: var(--blue);
  }
}
