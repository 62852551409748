.silo_page-header {
  padding: 100px 0 80px;
  background-image: url('../../../assets/images/About/page_head-bg.png');
  background-position: bottom;
  background-size: cover;
  @media screen and (max-width: 767px){
    padding: 50px 0;
  }
}

.about_box {
  .about_box-desc {
    max-width: 420px;
    margin-left: 50px;

    .icon {
      width: 90px;
      height: 90px;
      display: flex;
      border-radius: 50%;
      background: var(--white);
      box-shadow: var(--box-shadow);
      align-items: center;
      justify-content: center;

      > img {
        height: 50px;
      }
    }
  }
}

.about_box.about_box--alt {
  .about_box-desc {
    margin-left: auto;
    text-align: right;

    .icon {
      margin-left: auto;
      text-align: right;
    }
  }
}

.dotted-lines-holder {
  position: relative;

  .dotted-lines {
    position: absolute;
    left: 42%;
    height: 70%;
    top: 107px;
    z-index: -1;
    width: 20%;
  }
}

@media (max-width: 1200px) {
  .dotted-lines-holder {
    .dotted-lines {
      position: absolute;
      left: 35%;
      height: 74%;
      top: 51px;
      z-index: -1;
      width: unset;
    }
  }
}

@media (max-width: 1100px) {
  .dotted-lines-holder {
    .dotted-lines {
      display: none;
    }
  }
}

@media (max-width: 600px) {
  .about_box {
    .about_box-desc {
      margin-left: 0;
    }

    &:last-child {
      .about_box-desc {
        text-align: right;
        margin-left: auto;

        .icon {
          text-align: right;
          margin-left: auto;
        }
      }
    }
  }

  .about_box.about_box--alt {
    .about_box-desc {
      margin-left: 0;
      text-align: left;

      .icon {
        margin-left: 0;
        text-align: left;
      }
    }
  }
}
