.list-group-item:hover {
  color: var(--blue);
  background-color: #f1f1f1;
}
.list-group-item {
  font-size: 18px;
  margin-bottom: 20px;
  background-color: white;
  border: none;
  border-bottom-style: groove;
  border-bottom-width: 1px;
  border-color: var(--light_grey);
  cursor: pointer;
}

.list-group-item.active {
  color: var(--blue);
  font-weight: 600;
  background-color: #f1f1f1;
  border-color: var(--light_grey);
}

.help-btn {
  // position: absolute;
  bottom: 0;
  margin: 5px 15px;
  margin-top: 30px;
  border-radius: 4px;
  display: block;
  padding: 10px 15px;
  background: linear-gradient(310deg, rgb(33 161 255), rgb(2 71 202));
  .btn {
    color: #ffffff;
  }
}

.nav_icons_sidebar{
    display: flex;
    gap: 15px;
    justify-content: center;
    margin: 20px 0px;
    a{
      border: 1px solid #ccc;
      padding: 5px 10px;
      border-radius: 20px;
    }
}

.sidebar-col2{
  border-right: 1px solid var(--blue);
}
.sidebar-col1{
  padding: 0;
  border-right: 1px solid var(--blue);
  .sidebar{
    // margin-top: 40px;
    border-top: 1px solid #f1f1f1;
    .list-group{
      .list-group-item{
        margin-bottom: 0;
        padding: 15px 20px;
        font-size: 14px !important;
        font-weight: 600;
        border-left: 5px solid transparent;
        border-bottom: 1px solid #f1f1f1;
        span{
          font-size: 14px;
          font-weight: 600;
        }
        svg{
          font-size: 24px;
          width: 30px;
          margin-right: 6px;
        }
      }
      .active{
        border-color: #1263a9;

      }
    }
  }
  @media screen and (max-width: 1029px){
    position: fixed !important;
    overflow-x: auto; 
    height: calc(100vh - 75px) !important;
  }
}
.sub-child{
  .list-group-item{
    padding: 16px 0;
    font-size: 16px;
  } 
}

.help-accordion {

  .help-accordion-btn {
    button.accordion-button {
      background: linear-gradient(310deg, rgb(33, 161, 255), rgb(2, 71, 202));
      color: white;
      border: none;
      box-shadow: none;
      padding: 0;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 14px;
      &::after {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
      }
    }
  }
  .accordion-body {
    .help-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 41px;
      color: #1064db; 
    }
    text-align: center;
    font-size: 18px;
  }
}