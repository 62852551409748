:root {
  --red: #dc3545;
  --blue-color: #1263a9; /* Set your desired blue color code here */
  --white: #fff;
  --blue: #1263a9;
  --dark-blue: #0e528f;
  --light_blue: #f1f5f9;
  --light_blue--alt: #ebf7f7;
  --very-light_blue: #f7fbff;
  --form-blue: #cce6ff;
  --chat-button-blue: #007bff;
  --light_grey: #ddd;
  --very_light_grey:#f1f1f1;
  --grey: #666;
  --grey--alt: #999999;
  --box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
  --heavy-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  --font-family: 'Poppins', sans-serif;
  --border-radius: 40px;
  --navbar-height: 77.3px;
  --bs-font-sans-serif: system-ui, -apple-system, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
  --bs-gradient: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.15),
    hsla(0, 0%, 100%, 0)
  );
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
}

body {
  font-family: var(--font-family);
  font-size: 14px;
}


.float-right{
  float: right;
}
.float-left{
  float: left;
}

// Modal override
.silo_modal{
  .modal-header{
    background-color: var(--blue);
    color: white;
    .modal-title{
      h4{
        margin: 0
      }
    }
  }
}

.trash-icon{
  font-size: 30px;
  border: 2px solid #f44040;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border-radius: 60px;
}


// Common style
.mlr-n15{
    margin-left: -15px !important ;
    margin-right: -15px !important 
  }
.paid{
  position: relative;
  border-radius: 15px;
  .paidContent{
    border-radius: 15px;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.8);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    div{
      border: 3px solid #4CAF50;
      display: inline-block;
      padding: 5px 30px;
      border-radius: 10px;
      background: white;
    }
  }
}

.silo_page-header{
  ol{
    li{
      text-transform: capitalize;
    }
  }
  @media screen and (max-width: 767px){
    h1{
    font-size: 20px;
    text-align: center;
    }
    ol{
      justify-content: center;
      li{
        font-size: 14px;
      }
    }
  }
}
.font-12{
  font-size: 12px;
}
.font-16{
  font-size: 16px;
}
.pages-content-inner{
  margin-top: 30px;
  text-align: justify;
  h3{
    font-size: 26px;
  }
  p{
    font-size: 16px;
    margin: 30px 0;
    margin-top: 10px;
    color: #666;
    font-weight: 300;
  }
}
.alert{
  border-radius: 15px;
}
.accordion-item{
  border-radius: 15px !important;
  margin-bottom: 10px;
  border: unset;
  box-shadow: 0 0px 4px 0 #ccc;
  .accordion-button{
    border-radius: 14px 14px 0 0 !important; 
  }
  .accordion-button.collapsed{
    border-radius: 15px !important
  }
  .accordion-body{
    margin-bottom: 20px;
  }
}

.overflow-hidden{
  overflow: hidden !important;
}
.text-left{
  text-align: left;
}
.sub-heading_form {
    font-size: 16px;
    color: #444;
    border-bottom: 2px solid #ccc;
    font-weight: 400;
    padding-bottom: 4px;
}
.text-right{
  text-align: right;
}
.ml-five{
  margin-left: 5px;
}
.ml-n10{
  margin-left: -10px;
}

.ml-20{
  margin-left: 20px;
}
@media screen and (max-width: 767px){
  .w-150px-sm{
    width: 150px !important;
  }
  .p-0-sm{
    padding: 0 !important
  }
}
@media screen and (min-width: 768px){
  .ml-10-desk{
    margin-left: 10px !important;
  }
}
@media screen and (max-width: 991px){
  .ml-0-sm{
    margin-left: 0;
    text-align: center;
  }
}
.sticky-top{
  box-shadow: 0 1px 4px 0 #f1f1f1;
}
@media screen and (max-width: 991px){
  .sticky-top{
    height: 67px;
  }
}

.backgroundBlue {
  background-color: var(--blue-color);
}

.backgroundLightBlue {
  background-color: var(--light_blue--alt);
}

.fontWeightBold {
  font-weight: 600;
}

.border-blue,
.border-primary,
.text-blue,
.text-primary {
  color: var(--blue-color) !important;
}

.w-40 {
  width: 40%;
}

.text-decoration-none {
  text-decoration: none;
}

.btn.silo-btn__blue {
  border: 1px solid var(--blue);
  color: var(--white);
  background: var(--blue);
}


.btn.silo-btn__blue:hover {
  background: var(--dark-blue);
}

.btn.silo-btn__blue:active {
  background: var(--blue);
  color: var(--white);
}

.btn.silo-btn {
  border: 1px solid var(--blue);
  border-radius: var(--border-radius);
  padding: 10px 30px;
}

.btn.silo-btn-small {
  border: 1px solid var(--blue);
  border-radius: var(--border-radius);
  padding: 5px 20px;
}

.btn.silo-btn__white {
  border: 2px solid var(--light_grey);
  color: var(--blue);
  background: var(--white);
  // font-weight: bold;
}

.btn.silo-btn__white:hover {
  background: #f1f1f1;
}

.page_body {
  background-color: var(--light_blue);
}

.btn.silo-btn__blue--alt {
  border: 1px solid var(--white) !important;
  color: var(--white) !important;
  background: transparent;
}

.heavyBoxShadow {
  box-shadow: var(--heavy-box-shadow);
}

.container_background {
  background-color: var(--light_blue--alt);
}

.silo-trans-btn:hover {
  background-color: var(--light_blue);
  border-radius: var(--border-radius);
}
@media screen and (max-width: 991px){
  .silo-trans-btn{
    width: 100%;
    text-align: center;
  }
}

.bg-form-blue {
  background-color: var(--form-blue);
}

.btn-delete {
  border: 1px solid var(--red) !important;
}

.new-quote-btn{
  background-color: var(--grey--alt);
}


.card-theme{
  box-shadow: var(--box-shadow);
  border-radius: var(--bs-border-radius-xl);
  padding: 5% 5%;
  background-color: white;
}

// pricing plan
.pricing-plan{
  .box{
    // margin-bottom: 20px;
    .card{
      padding: 15px !important;
      border-radius: 20px;
      box-shadow: unset;
      height: 100%;
      .plan-type{
        font-size: 18px;
        background: white;
        width: calc(100% - 30px);
        margin: auto;
        padding: 10px 15px;
        border-radius: 50px;
        box-shadow: 0 3px 4px #ccc;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .plan-cost{
        font-size: 30px;
      }
      .plan-details{
        text-align: center;
        svg{
          height: 70px;
          width: 70px;
          margin-bottom: 20px;
          object-fit: contain;
        }
      }
      &:hover{
        border-color: #85aaca;
      }
      input[type="radio"]{
        width: 24px;
        height: 24px;
        border: 1px solid var(--blue);
      }
      .form-check-input:checked {
        background-color: var(--blue);
      }
    }
  }
  @media screen and (max-width: 576px){
    .box{ 
      .card{ 
        .plan-details{ 
          svg{
            height: 30px;
            width: 30px;
            margin-bottom: 0px;
          }
        }
        .plan-type{
          font-size: 16px;
          height: auto;
        }
        .plan-cost{
          font-size: 20px;
          .plan-cycle{
            font-size: 20px;;
          }
        }
        .fs-4.text-muted{
          font-size: 14px !important;
        }
      }
    }
  }
}


@media screen and (min-width: 768px){
  .d-none-desk{
    display: none;
  }
}
@media screen and (max-width: 767px){
  .w-100-sm{
    width: 100%;
  }
  .d-none-mobile{
    display: none;
  }
  .pricing-plan{
    .form-label{
      margin-top: 10px !important;
    }
  }
  .flex-direction-CR{
    flex-direction: column-reverse;
  }
  .flex-direction-C{
    flex-direction: column;
  }
  .align-items-C-sm{
    align-items: center
  }
  .text-center-sm{
    text-align: center !important;
  }
  .contact-form-box{
    margin-top: 50px;
  }
  .mt-20-sm-imp{
    margin-top: 20px !important; 
  }
}









// Animation progress
.ant-steps .ant-steps-item:last-child{
  flex: 1;
}
.ant-steps.ant-steps-dot .ant-steps-item-tail::after, .ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after{
  width: calc(100% + 12px);
  margin-left: -38px;
}
.ant-steps-horizontal.custom-steps .ant-steps-item-icon .ant-steps-icon{
  left: -42px;
  img{
    width: 30px;
    background: #f1f5f9;
    &:hover{
      transform: scale(1);
    }
  }
}
.quote-container .custom-steps .ant-steps-item-finish .ant-steps-item-tail::before {
  top: -18px;
  left: calc(100% - 29px);
}
.ant-steps .ant-steps-item-wait .ant-steps-item-icon >.ant-steps-icon .ant-steps-icon-dot{
  top: 15px;
}
.ant-steps-horizontal.custom-steps .ant-steps-item-icon .ant-steps-icon{
  top: 92px;
}
.quote-container .ant-steps-horizontal.custom-steps .ant-steps-item-content{
  padding: 20px 7px;
}
.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after{
  background-color: #008000;
}
.ant-steps{
  .ant-steps-item{
    &:before{
      content: "";
      height: 2px;
      background: #ccc;
      position: absolute;
      width: 30px;
      right: -30px;
      top: 44px;
    }
    &:last-child{
      &:before{
        content: unset;
      }
    }
  }
  .ant-steps-item-finish{
    .ant-steps-item-content{
      border-color: #1263a9 !important;
    }
    &:before{
      content: "";
      height: 2px;
      background: #1263a9;
      position: absolute;
      width: 30px;
      right: -30px;
      top: 44px;
    }
    &:last-child{
      &:before{
        content: unset;
      }
    }
    .quote-step-icon{
      background: var(--blue);
      color: white;  
      img{
        filter: invert(1) brightness(9);
      }
    }
  }
}

@media screen and (max-width: 1199px){
  .quote-step-title {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 991px){
  .quote-container .ant-steps-horizontal.custom-steps{
    .ant-steps-icon, .ant-steps-item-tail, .ant-steps-item-icon{
      display: none;
    }
  }
  .quote-step-title{
    display: block;
    font-size: 12px;
    font-weight: 400; 
    white-space: nowrap;
  }
  .ant-steps-horizontal.custom-steps{
    display: flex;
    justify-content: space-between;
    height: 50px;
    margin-top: 10px;

  }
  .ant-steps.ant-steps-label-vertical .ant-steps-item-icon{
    display: none;
  }
  .quote-container .ant-steps-horizontal.custom-steps {  
    .ant-steps-item-content{
      text-align: center;
      justify-content: center;
      padding: 10px;
      border: unset;
      background: unset;
    }
  }
  .ant-steps-item-content{  
    .quote-step-icon{
      display: inline-flex;
      margin: 0;
          background: #f1f5f9;
    }
  }
  .ant-steps{ 
    .ant-steps-item{
      &:before{
        width: 150px;
        right: -84px;
        top: 31px;
      }
    }
    .ant-steps-item-finish{
      &:before{
        width: 150px;
        right: -84px;
        top: 31px;
      }
    }
  }

  // override the progress 
  .tab-content{
    .ant-steps-item.ant-steps-item-process{
      &:before{
        content: unset !important;
      }
      .ant-steps-item-container>.ant-steps-item-tail{
        &:after{
          content: unset;
        }
      }
    }
    .ant-steps-label-horizontal{
      .ant-steps-item{
        max-width: 50%;
      }
      .ant-steps-item-tail{
        display: none;
        &:after{
          content: unset !important;
        }
      }
    }
    .ant-steps-item{
      width: 50%;
      .ant-steps-item-container{
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        text-align: center !important;
        .ant-steps-item-title{
          line-height: 20px;
          margin-top: 10px;
          padding-right: 0;
          font-size: 14px;
        }
        .ant-steps-item-icon{
          margin-right: 0;
        }
      }
    }
    .ant-divider{
      display: none;
    }
  }
}


@media screen and (max-width: 767px){
  .details-container, .current-container{
    box-shadow: 0 0 4px 0 #ccc !important;
  }
  .radio-label{
    margin-bottom: 10px;
    input[type="radio"]{
      width: 18px;
      height: 18px;
    }
    label{
      line-height: 22px;
      margin-left: 5px;
    }
  }
  .ant-steps-vertical.custom-steps {
    .quote-step-icon{
      display: block;  
    }
  }
  .ant-steps.custom-steps{ 
    .ant-steps-item{
      &:before{
      width: 120px;
      right: -67px;
      }
    }
    .ant-steps-item-finish{
      &:before{
        width: 100px;
        right: -60px;
      }
    }
  }
  .ant-steps.ant-steps-vertical{
        flex-direction: row;
  }
  .ant-steps.custom-steps.ant-steps-dot{
    .ant-steps-item-tail, .ant-steps-item-icon{
      display: none !important;
    }
  }

  
}
@media screen and (max-width: 575px){
  .ant-steps.custom-steps.ant-steps-dot{
    padding: 0 10px;
    padding-bottom: 40px !important;
    padding-top: 20px !important;
    .ant-steps-item-title{
      padding-right: 0;
    }
    .ant-steps-item-container{
      width: 50px;
      // background-color: #ccc;
      text-align: center;
    }
    .quote-step-title{
      position: absolute;
      left: -2px;
      display: block;
      white-space: normal;
      text-align: center;
      line-height: 15px;
      // background: gray;
      width: 70px;
      left: -13px;
      top: 47px;
      color: #111;
    }
     .quote-step-icon {
      padding: 0;
      display: block;
      width: 40px;
      height: 40px;
    }
  }
  .ant-steps.ant-steps-vertical >.ant-steps-item .ant-steps-item-content{
    overflow: inherit;
  }
  .ant-steps.ant-steps-vertical >.ant-steps-item{
    flex: auto !important;
    display: flex;
    justify-content: center;
  }
  .ant-steps{ 
    .ant-steps-item{
      &:before{
        top: 20px;
        width: calc(100% - 10%) !important;
        right: -45px !important;
      }
    }
  }

  .current-container{
    h3{
      font-size: 18px;
    }
  }
  .font-18-sm{
    font-size: 18px;
  }
  .tab-content{
    padding-left: 0;
    padding-right: 0;
  }
  .nav-pills{
    margin: 10px 0;
    .nav-item {
      .nav-link{
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .bg-area-sm{
    background-color: transparent !important;
    padding: 0 !important
  }
  .plr-0-sm{
    padding-left: 0 !important ;
    padding-right: 0 !important 
  }
  .mlr-n15-sm{
    margin-left: -15px !important ;
    margin-right: -15px !important 
  }
  .policy-mobile-container {
    background: transparent !important;
    box-shadow: unset !important;
    padding: 0 !important;
  }
  .accordion-item{
    .accordion-button{
        font-size: 16px;
        font-weight: 500;
      &:focus{
        box-shadow: unset;
      }
    }
  }
}


// Global Mobile responsive
@media screen and (max-width: 767px){
  .d-inherit-sm-imp{
    display: inherit !important;
  }
  .mt-10-sm{
    margin-top: 10px !important;
  }
  .mb-10-sm{
    margin-bottom: 10px !important;
  }
  .mobile-card{
    background: white;
    padding: 20px;
    border-radius: 30px;
    box-shadow: 0 3px 4px 0 #ccc;
  }
  .font-16-sm{
    font-size: 16px !important;
  }
  .font-28-sm{
    font-size: 28px !important;
  }
  .mb-0-sm-imp{
    margin-bottom: 0 !important;
  }
  .silo_page_section-heading{
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
  }
  .silo_page-banner{
    padding-top: 0 !important;
    display: inline !important;
  }
}
@media screen and (max-width: 575px){
  .react-datepicker-popper{
    width: 83% !important;
    margin-left: 0% !important;
  }
}
@media screen and (max-width: 767px){
  .mb-70-sm{
    margin-bottom: 70px;
  }
  .navigation_bottom{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0px 20px;
    text-align: right;
    background: white;
    border-top: 1px solid #ccc;
    z-index: 9;
    padding-bottom: 20px;
  }
  .navi_pad{
    padding: 10px 30px !important;
  }
  .a_100_sm{
    a{
      width: 100% !important;
    }
  }
  .get_quote_footer{
    height: 160px;
    footer{
      display: none
    }
  }
}