@import '../../../styles/antdStyles.scss';

.content-style {
  line-height: 100px;
  text-align: center;
  color: var(--blue);
  margin-top: 16px;
}

.custom-steps {
  padding: 100px 0 0px;
}

.ant-steps-horizontal.custom-steps {
  height: 150px;
  gap: 20px;
}

.ant-steps-horizontal.custom-steps
  .ant-steps-item-container
  .ant-steps-item-tail {
  top: 110px;
}

.ant-steps-horizontal.custom-steps .ant-steps-item-icon .ant-steps-icon {
  top: 90px;
  right: 10px;
}

.steps-content {
  min-height: 200px;
  margin: 2rem 4rem;
  text-align: center;
}

.ant-steps-item-finish {
  cursor: pointer;
}

@media (max-width: 494px) {
  .steps-content {
    margin: 1rem;
  }
}

.steps-action {
  margin-top: 24px;
}

.ant-steps-horizontal.custom-steps .ant-steps-item-content {
  margin-top: unset;
}

.ant-steps-horizontal.custom-steps .ant-steps-item-content {
  width: 280px;
}

.quote-container .design_element2 {
  position: absolute;
  right: 102px;
  top: 298px;
  z-index: -1;
}
.quote-container .design_element1 {
  position: absolute;
  right: 1411px;
  top: 343px;
  z-index: -1;
}

.ant-steps-vertical.custom-steps .quote-step-icon {
  display: none;
}

.quote-step-icon {
  border: 2px solid var(--blue);
  padding: 8px;
  border-radius: 60px;
  margin: 8px;
}

.quote-container .ant-steps-item-wait .ant-steps-item-title {
  .quote-step-icon {
    opacity: 0.7;
  }
  .quote-step-title {
    opacity: 0.7;
  }
}

.step-truck-icon:hover {
  transform: scale(1.5);
  transition: 0.5s;
}

.ant-steps-vertical.custom-steps .ant-steps-item-icon .ant-steps-icon > img {
  width: 25px;
}

.quote-container .custom-steps .ant-steps-item-finish .ant-steps-item-tail {
  position: relative;
  animation: carprogress 1.75s ease-in-out forwards;
}

/* Display the background trucking icon at the end of every finished step */
.quote-container
  .custom-steps
  .ant-steps-item-finish
  .ant-steps-item-tail::before {
  content: '';
  position: absolute;
  top: -10px;
  left: calc(100% - 15px);
  width: 30px;
  height: 30px;
  background: url('../../../assets/images/Quote/trucking.svg') no-repeat center
    center;
  background-size: cover;
  animation:
    carAnimation 1.75s ease-in-out forwards,
    fadeOut 0.3s 1.75s forwards;
}

@keyframes carprogress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes carAnimation {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(100% - 15px));
  }
}

/* Add animation to fade out the truck icon's background */
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.quote-container {
  min-height: 800px;
  @media screen and (max-width: 767px){
    min-height: auto !important;
    .new-quote-btn{
      margin-bottom: 0 !important;
    }
  }
}


// Device responsiveness
@media screen and (min-width: 1500px){
  .steps-content{
    width: 1470px;
    margin: 2rem auto;
  }
}

@media screen and (min-width: 1600px){
  .steps-content{
    width: 1500px;
  }
}

@media (max-width: 992px ) and (min-width: 576px) {
  .quote-step-title {
    display: none;
  }
}



@media screen and (max-width: 991px) and (min-width: 576px){
  .steps-content{
    position: relative;
    top: 30px;
  }
}
@media screen and (max-width: 575px){
  .steps-content{
    margin-top: 2rem !important;
  }
}